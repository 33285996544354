import BasePlugin from '../BasePlugin'

export default class CalculateAllInvestmentExpensesBudgetVATAmountAsyncCommand extends BasePlugin {
  async execute () {
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CalculateAllInvestmentExpensesBudgetVATAmountAsyncCommand`,
      { 'async': 'true' },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }
}
